/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:

$light:                           #fff !default;
$stable:                          #f8f8f8 !default;
$positive:                        #387ef5 !default;
$calm:                            #11c1f3 !default;
$balanced:                        #33cd5f !default;
$energized:                       #ffc900 !default;
$assertive:                       #ef473a !default;
$royal:                           #886aea !default;
$dark:                            #444 !default;
*/

// The path for our ionicons font files, relative to the built CSS in www/css
$ionicons-font-path: "../fonts" !default;

$light: #fff !default;
$stable: #f3f3f3 !default;
$positive: #0078b0 !default;
$calm: #50bbd9 !default;
$balanced: #99c640 !default;
$energized: #f3b62b !default;
$assertive: #e36056 !default;
$royal: #988bc5 !default;
$dark: #666 !default;

// Include all of Ionic
@import "../../app/lib/ionic/scss/ionic";
@import "../../app/lib/ionic/scss/ionicons/ionicons";

